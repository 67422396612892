.grid {
    display: grid;
}

.order-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 2rem 1rem;
    hr {
        margin: 0.7rem 0;
    }
    .leaflet-container {
        height: 200px;
    }
}

.table-list {
    display: grid;
    row-gap: 5px;
}

.add-button {
    max-width: fit-content;
    justify-self: end;
}

fieldset {
    border-color: #3f51b5;
    color: #3f51b5;
    padding: 0 1rem;
    &:has(table, .control-form, .basic-info-form) {
        padding-bottom: 1rem;
    }
    &:has(.basic-info-form) {
        padding-top: 0.5rem;
    }
}

.basic-info-form {
    display: flex;
    align-items: center;
}