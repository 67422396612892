.grid {
    display: grid;
}

.order-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    hr {
        margin: 0.7rem 0;
    }
    .leaflet-container {
        height: 200px;
    }
}

fieldset {
    border-color: #3f51b5;
    color: #3f51b5;
    padding: 0 1rem;
    &:has(table) {
        padding-bottom: 1rem;
    }
    &:has(.control-form) {
        padding: 1rem;
    }
    &:has(.basic-info-form) {
        padding-bottom: 1.5rem;
        padding-top: 1rem;
    }
}