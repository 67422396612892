.graph-container {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    margin-top: 1rem;
    height: 100%;
    overflow-y: auto;
    column-gap: 0.5rem;
    row-gap: 1.5rem;
}

.sections-wrapper {
    display: flex;
    flex-direction: column;
}

.sections-wrapper section {
    min-height: 800px;
}

section .legend {
    margin-bottom: 1rem;
}

.legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2rem ;
    gap: 1rem;
    height: 60px;
}

.graph {
    height: 300px;
    width: 100%;
    grid-column: span 2 / span 2;
}

.graph-large {
    grid-column: span 3 / span 3;
}

.heatmap {
    grid-row: span 2 / span 2;
}

.events-table {
    margin-top: 1rem;
    grid-column: span 6 / span 6;
    height: 40vh;
}

.cols-6 {
    grid-column: span 6 / span 6;
}

.divider {
    width: 100%;
    border-top: 1px solid #000;
    margin: 1.5rem 0;
}