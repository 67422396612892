.separate-box {
    display: flex;
    gap: 1rem;
    &:before {
        content: " ";
        width: 0;
        border-left: solid 1px black;
    }
}

.details_box {
    display: grid;
    margin-inline: auto;
    padding-inline: 8px;
    gap: 10px;
}
.driving_details {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.breaks_details {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}