.sc-gauge {
  width: 180px;
  height: 150px;
  margin: auto;
  margin-top: 10px;
}
.sc-background {
  position: relative;
  height: 100px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 150px 150px 0 0;
  overflow: hidden;
  text-align: center;
}
.sc-mask {
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  height: 80px;
  background-color: #f4f4f7;
  border-radius: 150px 150px 0 0;
}
.sc-percentage {
  position: absolute;
  top: 100px;
  left: -200%;
  width: 400%;
  height: 400%;
  margin-left: 100px;
  background-color: #3f51b5;
}
.sc-percentage {
  transform: rotate(25deg);
  transition: transform 1s;
  transform-origin: top center;
}
.sc-percentage2 {
  position: absolute;
  top: 100px;
  left: -200%;
  width: 400%;
  height: 400%;
  margin-left: 100px;
  background-color: red;
}
.sc-percentage2 {
  transform: rotate(25deg);
  transition: transform 1s;
  transform-origin: top center;
}
.sc-min {
  float: left;
}
.sc-max {
  float: right;
}
.sc-value {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  font-size: 28px;
  font-weight: 700;
}
.sc-sub-value {
  position: absolute;
  top: 80%;
  left: 0;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
