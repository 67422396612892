:root {
    --primary-color: #3f51b5;
    --base-spacing: 1rem;
}

#root:has(div[role="progressbar"]) {
    overflow: hidden;
}
#root:has(.auth-container) {
    .page-loader {
        padding-top: 0;
        justify-content: center;
    }
}


.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.gap-sm {
    gap: var(--base-spacing);
}

.gap-xs {
    gap: calc(var(--base-spacing) / 2);
}

.grid {
    display: grid;
}

.margin-inline-sm {
    margin-inline: var(--base-spacing);
}

.padding-block-sm {
    padding-block: var(--base-spacing);
}

.text-primary {
    color: var(--primary-color);
}

.align-self-end {
    align-self: flex-end;
}

.justify-self-end {
    justify-self: flex-end;
}